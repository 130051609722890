export const Footern = (props) => {
    return (
        
            <div className="all-brnds">
                <div className="container-fluid">
                    <h2>
                        <i className="fa fa-phone-square"></i> Call Us :: &nbsp;
                        <a href="tel:18005712141">18005712141</a>
                    </h2>
                    <p>
                   
                      <i className='fa fa-map-marker'></i> Adress :: &nbsp; 8-3-169/427, HF Nagar, Borababda, Ameerpet, Hyderabad - 500018
                    
                  </p>


                </div>
            </div>
           
        
    )
}